$primary-color: #00a8ff;

$component-active-bg: $primary-color;
$primary-button-bg: $primary-color;
$primary-button-border: $primary-color;

$link-color: $primary-color;

$selected-border: $primary-color;
$border-color: rgba( 197, 214, 222, .7);
$input-border: $border-color;
$input-focused-border: $border-color;
$base-border: $border-color;

@import '~@progress/kendo-theme-bootstrap/scss/all';
