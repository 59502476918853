
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/bootstrap";

// General stuff
body {
    font-family: 'Proxima Nova', sans-serif;
}

hr {
    margin: 2em 0 1em 0;
}

textarea.form-control {
    resize: vertical;
}

.color-input {
    height: 2.4rem;
}

.w-auto {
    width: auto !important;
}

// Buttons
.btn {
    i {
        vertical-align: middle;
        margin-top: -3px;
    }

    &.btn-sm {
        i {
            margin-top: -1px;
        }
    }

    // Button spinner
    &.is-loading {
        position: relative;

        color: transparent !important;
        text-shadow: none !important;

        opacity: .8;

        &:hover,
        &:active,
        &:focus {
            cursor: default;
        }

        &:before {
            content: '';

            position: absolute;
            top: 50%;
            left: 50%;

            display: inline-block;
            box-sizing: border-box;
            width: 1.5rem;
            height: 1.5rem;
            margin-top: -0.75rem;
            margin-left: -0.75rem;

            background: transparent;
            border: 1px solid #fff;
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-radius: 50rem;

            -webkit-animation: loader-spin 1s linear infinite;
            -moz-animation: loader-spin 1s linear infinite;
            -o-animation: loader-spin 1s linear infinite;
            animation: loader-spin 1s linear infinite;
        }

        &:before {
            border-color: #DDD #DDD #DDD #FFF;
        }

        &.btn-sm:before {
            width: 1.2rem;
            height: 1.2rem;
            margin-top: -0.6rem;
            margin-left: -0.6rem;
        }

        @-webkit-keyframes loader-spin {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        @-moz-keyframes loader-spin {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        @-o-keyframes loader-spin {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        @keyframes loader-spin {
            0% {
                transform: rotate(0deg);
            }
            50% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.btn.btn-half-with-margin {
    width: calc(50% - 8px);
    margin: 5px 4px;
}

/**
    Dashboard
**/
.box-typical-dashboard {
    .panel-heading {
        padding: 13px 20px;
    }

    .box-typical-header,
    .box-typical-footer {
        background-color: #f6f8fa;
    }
}

// Asides
.control-panel {

    .page-content {
        @media (max-width: 767px) {
            padding-right: 45px;
        }
    }

    .control-panel-container {

        @media (max-width: 767px) {
            display: block;
        }

        .control-item-header {
            background: #DCE5EC;

            &.active,
            &:hover,
            &:focus {
                background: #92A0AB !important;

                .panel-action {
                    color: #fff;
                }
            }

            .panel-action {
                text-align: center;
                display: block;
                vertical-align: middle;
                height: 44px;
                line-height: 44px;

                .text {
                    margin-left: 15px;
                    float: none;
                }
            }
        }

    }

    &.open {
        .page-content {
            padding-right: 200px;
        }

        .control-panel-container {
            width: 200px;

            .panel-action {
                border: none;
                height: auto !important;
                text-align: left;
                position: relative;

                .text {
                    display: inline;
                }
            }
        }
    }
}

// Inputs
.form-group {
    .k-input {
        width: 100%;
    }
}

.k-button {
    display: inline;
    font-weight: 600;
    font-size: 1rem !important;
    line-height: 1.25;
    padding: 0.5rem 1rem;

    &.btn-sm {
        padding: 0.25rem 0.5rem;
        font-size: $font-size-sm !important;
    }
}

// Error page
.page-error-box {

    width: 100%;
    max-width: 475px;
    margin: 0 auto;
    padding: 50px 30px 55px;

    text-align: center;

    color: #919fa9;
    line-height: 1;
    background-color: #fff;
    border: 1px solid #d8e2e7;
    -webkit-border-radius: 5px;
    border-radius: 5px;

    .error-icon {
        margin-bottom: 25px;

        font-size: 180px;
    }

    .error-code {
        font-size: 9.375rem;
        font-weight: 600;
    }

    .error-title {
        margin: 0 0 1.5rem;

        font-size: 2.25rem;
        font-weight: 600;
    }
}

// Grids
.k-grid {
    a {
        color: #0082c6;
    }

    td {
        // Temp fix until KendoUI Bootstrap v4 Theme is completed
        border-color: #e0e0e0;
    }

    .btn {
        margin-top: 1px;
        margin-bottom: 1px;

        &:hover {
            background-image: none;
        }
    }

    .search-grid-container {
        float: right;
    }

    .search-input {
        float: right;
        padding: 8px;
        margin-right: 7px;
        margin-left: 5px;
    }
}

// Dialogs
.k-dialog {
    .k-action-buttons {
        border-color: #EEE;
    }

    .k-dialog-buttongroup {
        border-color: #EEE;
    }
}

// Menu
.side-menu-list {
    .chart-icon {
        position: static;
        left: auto;
        top: auto;
    }
}

// Overwrite theme
.k-textbox {
    width: 100%;
    &.ng-invalid {
        border: 1px solid rgba(197,214,222,.7);
    }
}

.k-maskedtextbox {
    width: 100%;
}

.has-danger{
    .k-textbox.ng-invalid {
        border-color: #d9534f;
    }
}

.form-group {
    .table {
        margin-left: 15px;
        max-width: calc(100% - 30px);
    }
}

.k-edit-cell .k-widget,
.k-grid-edit-row .k-widget {
    &.k-switch {
        width: 6em;
        border-radius: 1.5em;
    }
}

.box-typical{
    .tbl-row{
        .tool-bar{
            padding: 10px;
            .btn{
                font-size: 15px;
            }
        }
    }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
